import React from 'react';
import axios from 'axios';
import './styles.css'

class Coverage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            QSLink: null
        };
    }

    componentDidMount() {
        // Simple GET request using axios
        axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/coverage')
            .then(response => this.setState({ QSLink: response.data }));
    }

    render() {
        const { QSLink } = this.state;
        return (
        <div className='full-height'>
            <iframe
            width="100%"
            height="900px"
            frameBorder='0'
            marginHeight = '0'
            marginWidth='0'
            src={QSLink}>
        </iframe>
        </div>);
    }
}

export { Coverage }; 

