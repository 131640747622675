import * as React from 'react';
import SignInForm from './SignInForm';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Login } from './test-login';

Amplify.configure(awsconfig);




const ResponsiveAppBar = () => {
  return (
    <Login />
    
  );
};
export default ResponsiveAppBar;
