import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Container from '@mui/material/Container';

import AdbIcon from '@mui/icons-material/Adb';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import logo from './RELLIS_ATM_logo_whiteREV.png'
import { GetURL } from './GetURL';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import './styles.css'
import attlogo from './att_hz_lg_lkp_rgb_wht.png'
import LabTabs from './tabs'

async function signOut() {
    try {
        await Auth.signOut();
        window.location.reload(false);
        console.log('Thanks for stopping by!')
    } catch (error) {
        console.log('error signing out: ', error);
    }
}



const ResponsiveAppBar = () => {

  const theme = createTheme({ 
    palette: {
    primary: {
      main: '#009FDB',
    }}})



  return (
    
    <ThemeProvider theme={theme}>
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box
        component="img"
        sx={{
          height: 1,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="The house from the offer."
        src={attlogo}
      />
        <Box
        component="img"
        sx={{
          height: 1,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="The house from the offer."
        src={logo}
      />
         
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          
          <button class ='signOut' onClick ={signOut}>Sign Out</button>
          
        </Toolbar>
      </Container>
    </AppBar>
    <LabTabs />
    <GetURL />
    </ThemeProvider>
  );
};
export default ResponsiveAppBar;
