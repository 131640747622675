import { Grid, Flex, Image, useTheme } from "@aws-amplify/ui-react";
import attlogo from './att_vt_lkp_rgb_pos.png'
import logo from './rellis-logo.png'


export function Header() {
  const { tokens } = useTheme();

  return (
    <Grid templateColumns={{ }}>

    <Flex justifyContent="center">
      <Image
        alt="attlogo"
        src={attlogo}
        padding={tokens.space.medium}
        height="200px"
      />
       <Image
        alt="rellislogo"
        src={logo}
        padding={tokens.space.medium}
        height='100px'
        marginTop='50px'
        marginRight='20px'
      />
    </Flex>
    </Grid>
  );
}
