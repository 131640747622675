import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import ResponsiveAppBar from './UI';
import './styles.css'
import logo from './rellis-logo.png'
import attlogo from './att_vt_lkp_rgb_pos.png'
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'
import { AmplifyProvider } from '@aws-amplify/ui-react'




class SignInForm extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            user: '',
            password: '',
            signedIn: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.signIn = this.signIn.bind(this);
        this.confirmSignIn = this.confirmSignIn.bind(this);
    }
  
    signIn() {
        const { username, password } = this.state;  
        Auth.signIn({
            username: username,
            password: password
        })
        .then(() => this.setState({signedIn:true}))
        .catch((err) => console.log(`Error signing in: ${ err }`))
    }
  
    confirmSignIn() {
        const { username } = this.state;
        Auth.confirmSignIn(username)
        .then(() => console.log('successfully confirmed signed in'))
        .catch((err) => console.log(`Error confirming sign up - ${ err }`))
    }
  
    handleSubmit(e) {
        e.preventDefault();

        // this.setState({
        //     username: '',
        //     password: '',
        //     //signedIn: true
        // });
        this.signIn();
        this.confirmSignIn()
        
        //e.target.reset();
    }
  
    handleChange(e) {
        if (e.target.id === 'username') {
          this.setState({
              username: e.target.value
          });
        } else if (e.target.id === 'password') {
          this.setState({
              password: e.target.value
          });
        }
    }
  
    render() {
      const { signedIn } = this.state;
      if (signedIn) {
          return (
              <ResponsiveAppBar />
          );
      } else {
        return (
            <div className = 'app'>
                <div className = 'login-form'>
          <div className="form">
              
            <form onSubmit={ this.handleSubmit }>
                <div className='input-container2'>
                <div className='logo-container'>
                <img className= 'logo' alt='att logo' src= {attlogo}/>
                <img className='amlogo' alt = 'rellis logo' src = {logo}/>
                </div>
                </div>
            <div className='input-container'>
                <div className='title'>Sign In</div>
                <label>Username</label>
                <input id='username' type='text' onChange={ this.handleChange }/>
                </div>
                <div className='input-container'>
                <label>Password</label>
                <input id='password' type='password' onChange={ this.handleChange }/>
                </div>
                <button className='sign-in-button-container'>Sign In</button>
            </form>
          </div>
          </div>
          </div>
        );
      }
    }
}

export default SignInForm;