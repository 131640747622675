import {
    AmplifyProvider,
    Authenticator,
    Flex,
    Grid,
    Image,
    useTheme,
    View
  } from "@aws-amplify/ui-react";
import { hover } from "@testing-library/user-event/dist/hover";
  
  import { Header } from "./header";
import ResponsiveAppBar from "./UI";
//   import { Footer } from "./Footer";
//   import { SignInHeader } from "./SignInHeader";
//   import { SignInFooter } from "./SignInFooter";
  
  const components = {
    Header,
    SignIn: {
      //Header: SignInHeader,
      //Footer: SignInFooter
    },
    //Footer
  };
  
  export function Login() {
    const { tokens } = useTheme();
    const theme = {
        name: 'pretty-princess',
        tokens: {
          colors: {
            background: {
              secondary: { value: 'white' },
              button: {value: 'white'}
              
            },
            font: {
                interactive: { value: '#009FDB' },
                secondary: { value: 'transparent' },
                hover:{value: 'transparent'}
               
            },
            brand: {
                primary: {
                  '10': tokens.colors.teal['90'],
                  '80': tokens.colors.teal['65'],
                  '90': tokens.colors.teal['90'],
                  '100': tokens.colors.teal['90']
                }
            }
        
          }
        }
      }
    
  
    return (
        <AmplifyProvider theme={theme}>
          <Authenticator components={components}>
            <ResponsiveAppBar />
          </Authenticator>
          </AmplifyProvider>

    );
  }