import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import { GetURL } from './GetURL';
import { Siteoverview } from './siteoverview';
import { Rankpis } from './rankpis';
import { Ranalarms } from './ranalarms';
import { Dataperimsi } from './dataperimsi';
import { Netact } from './netact';
import { Glossary } from './glossary';
import { Coverage } from './coverage';
import { Coresmf } from './coresmf';
import { Coreupf } from './coreupf';
import { Coreamf } from './coreamf';
import { Coreudm } from './coreudm';
import { Coreudr } from './coreudr';
import { Coreausf } from './coreausf';
import { Corepcf } from './corepcf';

export default function CoreTabs() {
    const [value, setValue] = React.useState('1');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);}
      return (
      <Box sx={{ width: '100%', typography: 'body1', mt: -3, ml: -2.8}}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="tabs">
              <Tab label="Core AMF KPIs" value="4" />
              <Tab label="Core SMF KPIs" value="5" />
              <Tab label="Core UPF KPIs" value="6" />
              <Tab label="Core UDM KPIs" value="7" />
              <Tab label="Core UDR KPIs" value="8" />
              <Tab label="Core AUSF KPIs" value="9" />
              <Tab label="Core PCF KPIs" value="10" />
              
            </TabList>
          </Box>
          <TabPanel value="4"> <Coreamf/> </TabPanel>
          <TabPanel value="5"> <Coresmf/> </TabPanel>
          <TabPanel value="6"> <Coreupf/> </TabPanel>
          <TabPanel value="7"> <Coreudm /> </TabPanel>
          <TabPanel value="8"> <Coreudr/> </TabPanel>
          <TabPanel value="9"> <Coreausf/> </TabPanel>
          <TabPanel value="10"> <Corepcf/> </TabPanel>
        </TabContext>
      </Box>
    );}