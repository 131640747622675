import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import { GetURL } from './GetURL';
import { Siteoverview } from './siteoverview';
import { Rankpis } from './rankpis';
import { Ranalarms } from './ranalarms';
import { Dataperimsi } from './dataperimsi';
import { Netact } from './netact';
import { Glossary } from './glossary';
import { Coverage } from './coverage';
import { Coresmf } from './coresmf';
import { Coreupf } from './coreupf';
import { Coreamf } from './coreamf';
import { Coreudm } from './coreudm';
import { Coreudr } from './coreudr';
import { Coreausf } from './coreausf';
import { Corepcf } from './corepcf';
import CoreTabs from './core-kpi-tabs';

// class LabTabs extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       value: '1',
//       siteoverview: null,
//       rankpis: null,
//       ranalarms: null,
//       coresmf: null,
//       coreupf: null,
//       dataimsi: null,
//       netact: null,
//       glossary: null,
//       coverage: null
//     }
//     this.handleChange = this.handleChange.bind(this);
//   }
//   componentDidMount() {
//     // Simple GET request using axios
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/siteoverview')
//         .then(overview => this.setState({ siteoverview: overview.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/rankpis')
//         .then(rankpi => this.setState({ rankpis: rankpi.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/ranalarms')
//         .then(ranalarm => this.setState({ ranalarms: ranalarm.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/core-upf')
//         .then(upf => this.setState({ coreupf: upf.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/core-smf')
//         .then(smf => this.setState({ coresmf: smf.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/dataperimsi')
//         .then(imsi => this.setState({ dataimsi: imsi.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/netact')
//         .then(netact => this.setState({ netact: netact.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/glossary')
//         .then(glossary => this.setState({ glossary: glossary.data }));
//     axios.get('https://pjd87o9c59.execute-api.us-east-1.amazonaws.com/prod/coverage')
//         .then(coverage => this.setState({ coverage: coverage.data }));
// }
//   handleChange(e) {
//     this.setState({value: this.state.value})
//   }

//   render() {
//     const { value } = this.state;
//     const { siteoverview } =this.state;
//     const { rankpis } = this.state;
//     const { ranalarms } = this.state;
//     const { coresmf } = this.state;
//     const { coreupf } = this.state;
//     const { dataimsi } =this.state;
//     const { netact } = this.state;
//     const { glossary } = this.state;
//     const { coverage } = this.state;
//     return (
//       <Box sx={{ width: '100%', typography: 'body1' }}>
//         <TabContext value={value}>
//           <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             <TabList onChange={this.handleChange} aria-label="lab API tabs example">
//               <Tab label="Site Overview" value = "1" onClick={() => this.setState({value: '1'})} />
//               <Tab label="RAN KPIs" value = "2" onClick={() => this.setState({value: '2'})} />
//               <Tab label="RAN Alarms" value="3" onClick={() => this.setState({value: '3'})}/>
//               <Tab label="Core SMF KPIs" value="4" onClick={() => this.setState({value: '4'})}/>
//               <Tab label="Core UPF KPIs" value="5" onClick={() => this.setState({value: '5'})}/>
//               <Tab label="Data per IMSI" value="6" onClick={() => this.setState({value: '6'})}/>
//               <Tab label="NetAct" value="7" onClick={() => this.setState({value: '7'})}/>
//               <Tab label="Glossary" value="8" onClick={() => this.setState({value: '8'})}/>
//               <Tab label="Coverage Predictions" value="9" onClick={() => this.setState({value: '9'})}/>
//             </TabList>
//           </Box>
//           <TabPanel value="1"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={siteoverview}> </iframe> </div> </TabPanel>
//           <TabPanel value="2"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={rankpis}> </iframe> </div> </TabPanel>
//           <TabPanel value="3"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={ranalarms}> </iframe> </div> </TabPanel>
//           <TabPanel value="4"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={coresmf}> </iframe> </div></TabPanel>
//           <TabPanel value="5"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={coreupf}> </iframe> </div></TabPanel>
//           <TabPanel value="6"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={dataimsi}> </iframe> </div></TabPanel>
//           <TabPanel value="7"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={netact}> </iframe> </div> </TabPanel>
//           <TabPanel value="8"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={glossary}> </iframe> </div></TabPanel>
//           <TabPanel value="9"> <div className='full-height'> <iframe width="100%" height="900px" frameBorder='0' marginHeight = '0' marginWidth='0' src={coverage}> </iframe> </div></TabPanel>
//         </TabContext>
//       </Box>
//     );

//   }
// }

// export default LabTabs;

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);}
    return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab label="Site Overview" value="1" />
            <Tab label="RAN KPIs" value="2" />
            <Tab label="Alarms" value="3" />
            <Tab label="Core KPIs" value="4" />
            <Tab label="Data per IMSI" value="11" />
            <Tab label="NetAct" value="12" />
            <Tab label="Glossary" value="13" />
            <Tab label="Coverage" value="14" />
            
          </TabList>
        </Box>
        <TabPanel value="1"> <Siteoverview/> </TabPanel>
        <TabPanel value="2"> <Rankpis/> </TabPanel>
        <TabPanel value="3"> <Ranalarms/> </TabPanel>
        <TabPanel value="4"> <CoreTabs/> </TabPanel>
        <TabPanel value="11"> <Dataperimsi/> </TabPanel>
        <TabPanel value="12"> <Netact/> </TabPanel>
        <TabPanel value="13"> <Glossary/> </TabPanel>
        <TabPanel value="14"> <Coverage/> </TabPanel>
      </TabContext>
    </Box>
  );


//   return (
//     <Box sx={{ width: '100%', typography: 'body1' }}>
//       <TabContext value={value}>
//         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//           <TabList onChange={handleChange} aria-label="lab API tabs example">
//             <Tab label="Site Overview" value="1" />
//             <Tab label="RAN KPIs" value="2" />
//             <Tab label="RAN Alarms" value="3" />
//             <Tab label="Core KPIs" value="4" />
//             <Tab label="Data per IMSI" value="5" />
//             <Tab label="NetAct" value="6" />
//             <Tab label="Glossary" value="7" />
//             <Tab label="Coverage Predictions" value="8" />
//           </TabList>
//         </Box>
//         <TabPanel value="1"> <Siteoverview/> </TabPanel>
//         <TabPanel value="2"> <Rankpis/> </TabPanel>
//         <TabPanel value="3"> <Ranalarms/> </TabPanel>
//         <TabPanel value="5"> <Dataperimsi/> </TabPanel>
//         <TabPanel value="6"> <Netact/> </TabPanel>
//         <TabPanel value="7"> <Glossary/> </TabPanel>
//         <TabPanel value="8"> <Coverage/> </TabPanel>
//       </TabContext>
//     </Box>
//   );
}